export enum Sex {
    Male = 'Male',
    Female = 'Female'
}

export enum Role {
    admin = 'admin',
    doctor = 'doctor',
    technician = 'technician',
    courier = 'courier',
    clinic = 'clinic',
    patient = 'patient',
    order = 'order'
  }


export enum Direction {
    ASC = 'ASC',
    DESC = 'DESC'
}

